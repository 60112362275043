import React from 'react';
import './AdminFooter.scss';

const AdminFooter = () => {
    return (
        <div className="footer">
            <span>Room Scout</span>
            <span>© Room Scout Admin Dashboard</span>
        </div>
    );
};
export default AdminFooter;